import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import SubPageNav from './SubPageNav';

const ComplaintsComplianceNav = ({ activePath }) => {
	const linksArr = [
		{
			text: <FormattedMessage id='builderNav.complaints1' />,
			path: '/builder-vendor/complaints-compliance-enforcement/complaints/'
		},
		{
			text: <FormattedMessage id='builderComplaints4.headerAalt' />,
			path: '/builder-vendor/complaints-compliance-enforcement/potential-registrar-actions/'
		},
		{
			text: <FormattedMessage id='adminPenalties.header' />,
			path: '/builder-vendor/complaints-compliance-enforcement/administrative-penalty/'
		},
		{
			text: <FormattedMessage id='builderNav.complaints2' />,
			path: '/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/'
		},
		{
			text: <FormattedMessage id='builderNav.complaints3' />,
			path: '/builder-vendor/complaints-compliance-enforcement/compliance-enforcement/'
		},
		{
			text: <FormattedMessage id='daCommittee.headerB' />,
			path: '/builder-vendor/complaints-compliance-enforcement/code-of-ethics/'
		}
	];

	const activeArr = linksArr.map((link) => {
		if (link.path === activePath) link['active'] = true;
		return link;
	});

	return <SubPageNav listStyle='unordered' links={activeArr} />;
};

export default ComplaintsComplianceNav;
